import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Button, Modal, Progress, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePartnerSettleQuery } from '../../generated/graphql';
import SettlementNotice from './SettlementNotice/SettlementNotice';

export default function SettlementInfo() {
  const theme = useDesignSystemTheme();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const { data, loading } = usePartnerSettleQuery();
  const bankName = data?.partnerSettle?.bankName || data?.partnerSettle?.bank?.name || '';

  function handleToggleModal() {
    if (language === 'ko') window.open('https://docs.google.com/forms/d/e/1FAIpQLScMAPGdU3yp3SMKh4RUMQNRIHf3KetixcGG7y6AIM_PTOzMOg/viewform?fbzx=6279660984734325168');
    else setShowModal((p) => !p);
  }

  function handleCopyEmailClick() {
    navigator.clipboard.writeText('contact@acon3d.com');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        width: '438px',
        flexShrink: 0,
        [theme.breakpoints.down('largeTablet')]: {
          width: '100%',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette['color/white'],
          borderRadius: '16px',
          padding: '24px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          [theme.breakpoints.down('largeTablet')]: {
            borderRadius: '0',
            gap: '16px',
            padding: '16px',
          },
        }}
      >
        {loading ? (
          // 로딩 프로그레스 ui
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '220px',
              width: '100%',
              [theme.breakpoints.down('largeTablet')]: {
                height: '196px',
              },
            }}
          >
            <Progress />
          </Box>
        ) : bankName ? (
          <>
            {/*  계좌 정보  */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography variant={'typography/label/large/regular'}>{t('settlementPage.info.account')}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                <Typography variant={'typography/body/medium/bold'}>{bankName}</Typography>
                <Typography variant={'typography/body/medium/bold'}>{data.partnerSettle.bankAccountNumber}</Typography>
              </Box>
            </Box>
            {/*  예금주  */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography variant={'typography/label/large/regular'}>{t('settlementPage.info.name')}</Typography>
              <Typography variant={'typography/body/medium/bold'}>{data.partnerSettle.bankAccountOwner}</Typography>
            </Box>
            {/*  desktop 버튼  */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Button
                variant={'outlined'}
                size={'L'}
                fullWidth={true}
                onClick={handleToggleModal}
                sx={{
                  [theme.breakpoints.down('largeTablet')]: {
                    display: 'none',
                  },
                }}
              >
                {t('settlementPage.info.editButton')}
              </Button>
              {/*  mobile 버튼  */}
              <Button
                variant={'outlined'}
                size={'M'}
                fullWidth={true}
                onClick={handleToggleModal}
                sx={{
                  display: 'none',
                  [theme.breakpoints.down('largeTablet')]: {
                    display: 'block',
                  },
                }}
              >
                {t('settlementPage.info.editButton')}
              </Button>
              <Typography variant={'typography/body/small/regular'}>{parse(t('settlementPage.inquiryInfo'))}</Typography>
            </Box>
          </>
        ) : (
          <>
            <Typography variant={'typography/body/small/regular'} fontSize={12} fontWeight={500} lineHeight={'16px'}>
              {parse(t('settlementPage.info.noInfo.text'))}
            </Typography>
            <Link to={'/userSettle'}>
              <Button variant={'outlined'} size={'L'} fullWidth={true}>
                {t('settlementPage.info.noInfo.registerButton')}
              </Button>
            </Link>
          </>
        )}
        <Modal
          open={showModal}
          onClose={handleToggleModal}
          size={'M'}
          sx={{
            '& .modal-content': {
              border: 'none',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
          renderContent={
            <Typography align={'center'} variant={'typography/body/medium/regular'}>
              {parse(t('settlementPage.info.modal.content'))}
            </Typography>
          }
          renderActions={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Button variant={'outlined'} size={'L'} onClick={handleToggleModal}>
                {t('settlementPage.info.modal.action.close')}
              </Button>
              <Button variant={'contained'} size={'L'} color={'primary'} onClick={handleCopyEmailClick} fullWidth={true}>
                {t('settlementPage.info.modal.action.copyEmail')}
              </Button>
            </Box>
          }
        />
      </Box>
      {/*  안내 문구  */}
      <SettlementNotice
        containerSx={{
          padding: '24px',
          borderRadius: '16px',
          backgroundColor: theme.palette['color/grayishViolet/200'],
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          [theme.breakpoints.down('largeTablet')]: {
            display: 'none',
          },
        }}
      />
    </Box>
  );
}
