import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import countryData from '../assets/iso_3166';
import { FormGroup, FormTitle, SectionTitle } from '../style';
import { SelectPicker } from 'rsuite';
import { Stack, Typography } from '@mui/material';
import { Checkbox, Divider, Typography as TypographyCarpen } from 'carpenstreet-designsystem';
import { FormProvider } from 'components/hook-form';
import { SignupChapterEnum, PartnerTypeEnum } from '../type';
import { LanguageCodeEnum } from 'generated/graphql';
import { useForm } from 'react-hook-form';
import OldRHFTextField from './OldRHFTextField';
import { useRecoilState } from 'recoil';
import { chapterState, contractFormState, userTypeState } from 'signup/SignUp.atoms';
import * as Yup from 'yup';
import './3.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import Navigator from 'signup/Navigator';
import { isBusinessRrn } from 'utils/validation';

type ContractInformationFormValues = {
  rrnFront?: string;
  rrnBack?: string;
  name?: string;
  contact?: string;
  country?: string;
  taxPayerId?: string;
  companyName?: string;
  rrn?: string;
};

export default function ContractInformationForm() {
  // 번역 도구
  const { t, i18n } = useTranslation();

  const [type] = useRecoilState(userTypeState);
  const [, setChapterValid] = useRecoilState(chapterState);
  const [, setContractForm] = useRecoilState(contractFormState);
  const [isAgreement, setIsAgreement] = useState(false);

  const formSchema = Yup.object().shape({});

  const defaultValues: ContractInformationFormValues = {
    // 주민 등록 번호 (국내 사용자에게만 사용)
    rrnFront: '',
    rrnBack: '',
    // 이름 (국내 사용자에게만 사용)
    name: '',
    contact: '',
    // 국가 (해외 사용자에게만 사용)
    country: '',
    // 해외세무자 번호 (해외 사용자에게만 사용)
    taxPayerId: '',

    // 상호명
    companyName: '',
    // 사업자 등록 번호
    rrn: '',
  };

  const methods = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit, setValue, setFocus, watch, register } = methods;
  const values = watch();

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  // 주민번호 앞자리
  const setFrontRrn = (value) => {
    const result = value.replaceAll(/\D/g, '');

    if (result.length === 6) {
      setFocus('rrnBack');
    }

    setValue('rrnFront', result);
  };

  // 주민번호 뒷자리
  const setBackRrn = (value) => {
    const result = value.replaceAll(/\D/g, '');
    setValue('rrnBack', result);
  };

  // 사업자등록번호 (국문몰 한정)
  const setRrn = (value: string) => {
    // 10자리 숫자만 받고 나머지는 무시합니다. 3번째와 5번째 숫자 뒤에 자동으로 '-'을 추가합니다.
    const numbersOnly = value.replace(/[^0-9]/g, '').slice(0, 10);
    let formatted = '';
    for (let i = 0; i < numbersOnly.length; i += 1) {
      if (i === 3 || i === 5) {
        formatted += '-';
      }
      formatted += numbersOnly[i];
    }

    setValue('rrn', formatted);
  };

  const onNameChange = (e) => {
    let value = e.target.value;

    if (i18n.language !== LanguageCodeEnum.Ko) {
      value = value.replace(/[^A-Za-z ]/gi, '');
    }

    setValue('name', value);
  };

  const onTaxPayerIdChange = (e) => {
    let value = e.target.value;
    setValue('taxPayerId', value);
  };

  const onContactChange = (e) => {
    const value = e.target.value.replace(/[^0-9\-]/, '');
    setValue('contact', value);
  };

  let countries = countryData.map((x) => {
    return {
      label: x.Name,
      value: x.Code,
      role: 'Master',
    };
  });

  if (i18n.language !== LanguageCodeEnum.Ko) countries = countries.filter((x) => !['KR', 'KP'].includes(x.value));

  const setCurrentChapterValid = (valid: boolean) => {
    setChapterValid((prev) => {
      return {
        ...prev,
        [SignupChapterEnum.Payment]: valid,
      };
    });
  };

  // 사업자 - 상호명 / 대표자 이름 / 사업자 등록번호
  // 해외 - 이름 / 나라
  // 개인 - 이름 / 주민번호

  useEffect(() => {
    const { name, rrn, rrnFront, rrnBack, companyName, country, contact, taxPayerId } = values;

    if (type) {
      if (type === PartnerTypeEnum.Business) {
        const isRrnValid = i18n.language !== LanguageCodeEnum.Ko || isBusinessRrn(rrn);
        setCurrentChapterValid(!!(companyName && name && isRrnValid && contact && isAgreement));
      } else if (i18n.language !== LanguageCodeEnum.Ko && type === PartnerTypeEnum.Personal) {
        setCurrentChapterValid(!!(name && country && taxPayerId && isAgreement));
      } else {
        setCurrentChapterValid(!!(name && rrnFront && rrnFront.length === 6 && rrnBack && rrnBack.length === 7 && contact && isAgreement));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, values.name, values.rrn, values.rrnFront, values.rrnBack, values.companyName, values.country, values.contact, isAgreement, values.taxPayerId]);

  const handleNext: () => Promise<string> = async () => {
    return new Promise((resolve) => {
      setContractForm({
        name: values.name,
        companyName: values.companyName,
        contact: values.contact,
        rrn: values.rrnFront && values.rrnBack ? `${values.rrnFront}-${values.rrnBack}` : values.rrn,
        taxPayerId: values.taxPayerId,
        country: values.country,
      });
      resolve('');
    });
  };

  return (
    <>
      <FormProvider methods={methods}>
        <Stack>
          <SectionTitle>{t('signupPage.paymentInfo')}</SectionTitle>
          <Stack spacing={4.5}>
            {type === PartnerTypeEnum.Business && (
              <FormGroup>
                <FormTitle>상호명</FormTitle>
                <OldRHFTextField name={'companyName'} />
              </FormGroup>
            )}
            <FormGroup>
              <FormTitle>{type === PartnerTypeEnum.Business ? '대표자' : t('signupPage.name')}</FormTitle>
              <OldRHFTextField name="name" onChange={onNameChange} placeholder={t('signupPage.placeholder.name')} />
            </FormGroup>

            {i18n.language !== LanguageCodeEnum.Ko && (
              <FormGroup>
                <FormTitle>{'Country of Residence'}</FormTitle>
                <SelectPicker
                  name="country"
                  onChange={(value) => {
                    setValue('country', value);
                  }}
                  value={values.country}
                  className={isSelectOpen && 'open'}
                  size="lg"
                  data={countries}
                  style={{ width: '100%', height: 48 }}
                  cleanable={false}
                  placeholder={<span className="select-placeholder">{'Please select country of residence based on your current legal address.'}</span>}
                  onOpen={() => setIsSelectOpen(true)}
                  onClose={() => setIsSelectOpen(false)}
                  caretAs={'ArrowDownIcon'}
                />
              </FormGroup>
            )}

            {i18n.language === LanguageCodeEnum.Ko && type === PartnerTypeEnum.Personal && (
              <FormGroup>
                <FormTitle>주민등록번호</FormTitle>
                <Stack spacing={1}>
                  <Typography fontSize={12} color={'#7c7c7c'} lineHeight={'130%'}>
                    계약서 작성을 위해 정확하게 기입해주세요.
                    <br />
                    (기입된 정보는 개인정보처리방침에 의거하여 안전하게 보관 및 활용됩니다.)
                  </Typography>
                  <Stack direction="row">
                    <OldRHFTextField name="rrnFront" onChange={(e) => setFrontRrn(e.target.value)} maxLength={6} />
                    <Stack width={'28px'} justifyContent={'center'} ml={1} mr={1}>
                      <div
                        style={{
                          border: '1px solid #cdcdcd',
                          width: '12px',
                          height: '1px',
                        }}
                      ></div>
                    </Stack>
                    <OldRHFTextField type="password" name="rrnBack" onChange={(e) => setBackRrn(e.target.value)} maxLength={7} />
                  </Stack>
                </Stack>
              </FormGroup>
            )}

            {i18n.language === 'ko' && type === PartnerTypeEnum.Business && (
              <FormGroup>
                <FormTitle>{'사업자등록번호'}</FormTitle>
                <OldRHFTextField name="rrn" onChange={(e) => setRrn(e.target.value)} maxLength={12} placeholder="“-”를 제외하고 입력해주세요." />
              </FormGroup>
            )}

            {i18n.language !== 'ko' && (
              <FormGroup>
                <FormTitle>{'Taxpayer Identification Numbers (TIN) '}</FormTitle>

                <OldRHFTextField
                  name="taxPayerId"
                  onChange={onTaxPayerIdChange}
                  placeholder="Taxpayer Identification Numbers (TIN), Identification Number, Passport Number, etc."
                />
              </FormGroup>
            )}

            {i18n.language === 'ko' && (
              <FormGroup>
                <FormTitle>{'연락처'}</FormTitle>
                <OldRHFTextField name="contact" onChange={onContactChange} />
              </FormGroup>
            )}
          </Stack>
          <Divider sx={{ mt: '48px', mb: '40px' }} />
          <Stack justifyContent="space-between" direction="row">
            <TypographyCarpen variant="typography/body/small/medium" color="color/gray/800">
              {t('signupPage.policy.agreement')}
            </TypographyCarpen>
            <a href="/policy/agreement" target="_blank">
              <TypographyCarpen variant="typography/label/large/regular" color="color/gray/400" sx={{ textDecoration: 'underline' }}>
                {t('signupPage.policy.detail')}
              </TypographyCarpen>
            </a>
          </Stack>
          <Stack justifyContent="space-between" direction="row" mt={1}>
            <TypographyCarpen variant="typography/body/small/medium" color="color/gray/800">
              {t('signupPage.policy.privacy')}
            </TypographyCarpen>
            <a href="/policy/privacy" target="_blank">
              <TypographyCarpen variant="typography/label/large/regular" color="color/gray/400" sx={{ textDecoration: 'underline' }}>
                {t('signupPage.policy.detail')}
              </TypographyCarpen>
            </a>
          </Stack>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap="8px" mt={2}>
            <Checkbox size="M" color="primary" checked={isAgreement} onChange={() => setIsAgreement(!isAgreement)} />
            <TypographyCarpen variant="typography/body/small/regular" color="color/gray/800">
              {t('signupPage.policy.policyDescription01')}
            </TypographyCarpen>
            <TypographyCarpen variant="typography/body/small/regular" color="color/primary/600">
              {t('signupPage.policy.required')}
            </TypographyCarpen>
          </Stack>
        </Stack>
      </FormProvider>
      <Navigator onNext={handleNext} />
    </>
  );
}
